import React, { useEffect, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { cpf } from 'cpf-cnpj-validator';

import Select from 'components/Select';
import Input from 'components/Input';
import CustomInput from 'components/Input/CustomInput';
import FormTitle from 'components/FormTitle';
import BottomNavigation from 'components/wizard/BottomNavigation';
import Button from 'components/Button';

import schema from 'schemas/PersonalData';
import personIcon from 'assets/img/steps/person-icon.svg';

import { genders, pcd, maritalStatus } from 'constants/selectData';
import { StepContext } from 'contexts/StepContext';
import { HashContext } from 'contexts/HashContext';
import EmailService from 'services/EmailService';
import useSentryContext from 'hooks/useSentryContext';
import useAPICall from 'hooks/useAPICall';

export default function PersonalDataForm({
  form,
  initialValues,
  isCompleted,
  isArteris,
  isEdit = false,
  onChangeSave,
  onChangeCancel,
}) {
  const { setCurrentStep } = useContext(StepContext);
  const { decodedHash, hash } = useContext(HashContext);
  const { handleAPICall } = useAPICall();
  const [sentryEmail, setSentryEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line consistent-return
  async function emailIsValid(email) {
    setLoading(true);

    try {
      const urlEncoded = new URLSearchParams();
      urlEncoded.append('data[User][mail]', email);

      const response = await handleAPICall(() =>
        EmailService.checkEmail(urlEncoded)
      );

      if (response.msg === false) {
        setSentryEmail(email);
        return true; // email valido
      }

      return false;
    } catch (error) {
      console.log('email validation', error);
    } finally {
      setLoading(false);
    }
  }

  useSentryContext({ hash, decodedHash, email: sentryEmail || '' });

  async function handleNext(goToNext, steps, step, value, setFieldError) {
    if (steps.length - 1 <= steps.indexOf(step)) {
      return;
    }

    if (!form.current.isValid) {
      return;
    }

    const isValid = await emailIsValid(value);
    if (isValid) {
      onChangeSave();
      step.isDone = true;
      goToNext();
    } else {
      if (!isValid) {
        setFieldError(
          'email',
          'Esse endereço de email já está cadastrado no sistema'
        );

        return;
      }

      setCurrentStep((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  }

  async function handleEdit(value, setFieldError) {
    const isValid = await emailIsValid(value);

    if (isValid && form.current.isValid) {
      onChangeSave();
    }

    if (!isValid) {
      setFieldError(
        'email',
        'Esse endereço de email já está cadastrado no sistema'
      );
    }
  }

  function handleCancel() {
    onChangeCancel();
  }

  function validateCPF(value) {
    let error;
    const valueReplaced = value?.replaceAll('.', '').replace('-', '');

    if (!cpf.isValid(valueReplaced)) {
      error = 'CPF inválido';
    }

    if (!value) {
      error = 'Campo obrigatório';
    }

    return error;
  }

  useEffect(() => {
    setCurrentStep({ name: 'personalData', title: 'Dados Pessoais' });
  }, [setCurrentStep]);

  return (
    <div className="resume-form-container">
      <Formik
        innerRef={form}
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={schema}
        validateOnMount
      >
        {({
          setFieldValue,
          setFieldError,
          setFieldTouched,
          values,
          errors,
          touched,
        }) => (
          <Form className="av-tooltip tooltip-label-bottom content-form">
            <div className="content-row row">
              <div className="col-12 form-title">
                <FormTitle icon={personIcon} title="Dados Pessoais" />
              </div>
              <div className="col-12 col-lg-6">
                <Input
                  label="Nome Completo"
                  fieldName="fullname"
                  placeholder="Ex: João da Silva"
                  errors={errors.fullname}
                  touched={touched.fullname}
                />

                <Input
                  label="Email"
                  fieldName="email"
                  placeholder="Ex: meuemail@exemplo.com"
                  errors={errors.email}
                  touched={touched.email}
                  fieldType="email"
                />

                <CustomInput
                  placeholder="Ex: 21 92246-5639"
                  label="Telefone"
                  type="text"
                  fieldName="phone"
                  onChange={setFieldValue}
                  value={values.phone}
                  errors={errors.phone}
                  touched={touched.phone}
                />

                <Input
                  label="Nacionalidade"
                  placeholder="Ex: Brasileiro"
                  fieldName="nacionality"
                  errors={errors.nacionality}
                  touched={touched.nacionality}
                />
              </div>

              <div className="col-12 col-lg-6">
                <Select
                  label="Estado Civil"
                  id="maritalStatus"
                  fieldName="maritalStatus"
                  placeholder="Ex: Solteiro"
                  options={maritalStatus}
                  value={values.maritalStatus}
                  onChange={setFieldValue}
                  errors={errors.maritalStatus}
                  touched={touched.maritalStatus}
                />

                <Select
                  label="Pessoa com Deficiência"
                  id="pcd"
                  fieldName="pcd"
                  placeholder="Ex: Não possuo"
                  options={pcd}
                  value={values.pcd}
                  onChange={setFieldValue}
                  errors={errors.pcd}
                  touched={touched.pcd}
                />

                <Select
                  label="Gênero"
                  id="gender"
                  fieldName="gender"
                  placeholder="Ex: Masculino"
                  options={genders}
                  value={values.gender}
                  onChange={setFieldValue}
                  errors={errors.gender}
                  touched={touched.gender}
                />

                <CustomInput
                  label="Data de Nascimento"
                  fieldName="bornDate"
                  placeholder="Ex: 18/04/1998"
                  value={values.bornDate}
                  onChange={setFieldValue}
                  errors={errors.bornDate}
                  touched={touched.bornDate}
                />
              </div>

              <div className="col-12 col-lg-6">
                {isArteris && (
                  <CustomInput
                    placeholder="Ex: 111.222.333-44"
                    label="CPF"
                    type="text"
                    validate={validateCPF}
                    fieldName="cpf"
                    onChange={setFieldValue}
                    setErrors={setFieldError}
                    value={values.cpf}
                    errors={errors.cpf}
                    touched={touched.cpf}
                  />
                )}
              </div>
            </div>

            {isEdit ? (
              <div className="d-flex w-100 form-buttons">
                <Button
                  label="Cancelar"
                  color="secondary"
                  className="w-100 mr-2"
                  onClick={handleCancel}
                />
                <Button
                  type="submit"
                  label="Salvar"
                  className="w-100"
                  onClick={() =>
                    handleEdit(values.email, setFieldError, setFieldTouched)
                  }
                />
              </div>
            ) : (
              <BottomNavigation
                isCompleted={isCompleted}
                onClickNext={(goToNext, steps, step) => {
                  handleNext(
                    goToNext,
                    steps,
                    step,
                    values.email,
                    setFieldError
                  );
                }}
                onClickPrev={() => {}}
                className="justify-content-center"
                prevLabel="Voltar"
                nextLabel="Próximo"
                isLoading={isLoading}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
