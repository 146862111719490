/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Formik, Form } from 'formik';

import Button from 'components/Button';
import Input from 'components/Input';
import InputMasked from 'components/Input/InputMasked';
import Select from 'components/Select';
import BottomNavigation from 'components/wizard/BottomNavigation';
import FormTitle from 'components/FormTitle';

import schema from 'schemas/Localization';
import localizationIcon from 'assets/img/steps/localization-icon.svg';
import LocalizationService from 'services/LocalizationService';

import useCanBackStep from 'hooks/useCanBackStep';
import useLoading from 'hooks/useLoading';
import useDisableFields from 'hooks/useDisableFields';
import useAPICall from 'hooks/useAPICall';

import { StepContext } from 'contexts/StepContext';

export default function LocalizationForm({
  form,
  initialValues,
  isCompleted,
  isEdit = false,
  onChangeSave,
  onChangeCancel,
}) {
  const { handleCanBackStepStorage } = useCanBackStep();
  const { setLoader, removeLoader, getLoaderFromName } = useLoading();
  const { handleAPICall } = useAPICall();
  const { setDisabledFields, removeDisabledFields, getDisabledFieldFromName } =
    useDisableFields();

  const { setCurrentStep } = useContext(StepContext);
  const [addressIsVisible, setAddressVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryId, setCountryId] = useState('76');
  const [stateId, setStateId] = useState('');

  const getStateFromCep = useCallback(
    (ufId) => {
      let state;

      if (Object.keys(states).length > 0) {
        state = states.find((item) => item.Estado.id === ufId);
      }

      return state;
    },
    [states]
  );

  const getCityFromCep = useCallback(async (state, cityParam) => {
    const res = await handleAPICall(() =>
      LocalizationService.listCitiesByStateId(state.Estado.id)
    );
    let city;

    if (Object.keys(res).length > 0) {
      setCities(res);
      city = res.find((item) => item.Cidade.id === cityParam);
    }

    return city;
  }, []);

  const handleCep = useCallback(
    async (e, setValues) => {
      const formatedCep = e.target.value.replace(/[^a-zA-Z0-9]+/g, '');

      if (formatedCep.length < 8) {
        return;
      }

      setLoader('cep');

      try {
        const response = await handleAPICall(() =>
          LocalizationService.findCep(formatedCep)
        );

        if (response) {
          const state = getStateFromCep(response.uf);
          const city = await getCityFromCep(state, response.Cidade);
          setValues((prevState) => ({
            ...prevState,
            street: response.logradouro,
            neighborhood: response.bairro,
            state,
            city,
          }));
        }
      } catch (error) {
        console.error('CEP_ERROR', error);
      } finally {
        removeLoader('cep');
        setAddressVisible(true);
      }
    },
    [getCityFromCep, getStateFromCep, removeLoader, setLoader]
  );

  function handleNext(goToNext, steps, step) {
    if (steps.length - 1 <= steps.indexOf(step)) {
      return;
    }

    if (form.current.isValid) {
      onChangeSave();
      step.isDone = true;
      goToNext();
    } else {
      setCurrentStep((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  }

  function handlePrev(goToPrev, steps, step) {
    if (steps.indexOf(step) <= 0) {
      return;
    }
    handleCanBackStepStorage();
    goToPrev();
  }

  function handleEdit() {
    if (form.current.isValid) {
      onChangeSave();
    }
  }

  function handleCancel() {
    onChangeCancel();
  }

  function handleSelectCountry(setFieldValue, id) {
    setCountryId(id);
    setFieldValue('state', '');
    setFieldValue('city', '');
  }

  function handleSelectState(setFieldValue, id) {
    setStateId(id);
    setFieldValue('city', '');
  }

  useEffect(() => {
    (async () => {
      setDisabledFields(['city', 'state']);

      try {
        const response = await handleAPICall(() =>
          LocalizationService.listAllCountries()
        );

        if (Object.keys(response).length > 0) {
          setCountries(response);
        }
      } catch (error) {
        console.error('LIST_COUNTRIES::ERROR', error);
      } finally {
        removeDisabledFields([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!countryId) {
        return;
      }

      setDisabledFields(['city']);

      try {
        const response = await handleAPICall(() =>
          LocalizationService.listAllStatesByCountryId(countryId)
        );

        if (Object.keys(response).length > 0) {
          setStates(response);
        }
      } catch (error) {
        console.error('LIST_STATES::ERROR', error);
      } finally {
        removeDisabledFields();
      }
    })();
  }, [countryId, handleAPICall]);

  useEffect(() => {
    (async () => {
      if (!stateId) {
        return;
      }

      try {
        const response = await handleAPICall(() =>
          LocalizationService.listCitiesByStateId(stateId)
        );

        if (Object.keys(response).length > 0) {
          setCities(response);
        }
      } catch (error) {
        console.error('LIST_CITIES', error);
      }
    })();
  }, [stateId]);

  useEffect(() => {
    setCurrentStep({ name: 'localization', title: 'Endereço' });
  }, [setCurrentStep]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Localização', true, true]);
  }, []);

  return (
    <div className="resume-form-container">
      <Formik
        innerRef={form}
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={schema}
        validateOnMount
      >
        {({
          setFieldValue,
          setValues,
          setFieldError,
          values,
          errors,
          touched,
        }) => (
          <Form className="av-tooltip tooltip-label-bottom content-form">
            <div className="content-row row">
              <div className="col-12 form-title">
                <FormTitle icon={localizationIcon} title="Endereço" />
              </div>

              <div className="col-12 col-lg-6">
                <Select
                  label="País"
                  placeholder="Ex: Brasil"
                  fieldName="country"
                  options={countries}
                  onChange={setFieldValue}
                  value={values.country}
                  getOptionLabel={(option) => option.Pais?.nome}
                  getOptionValue={(option) => option.Pais?.id}
                  onValueId={(item) =>
                    handleSelectCountry(setFieldValue, item.Pais?.id)
                  }
                  errors={errors.country}
                  touched={touched.country}
                />
              </div>

              <div className="col-12 col-lg-6">
                {values.country && values.country?.Pais?.id === '76' ? (
                  <InputMasked
                    placeholder="Ex: 22753037"
                    label="CEP"
                    fieldName="cep"
                    format="#####-###"
                    onChange={setFieldValue}
                    value={values.cep}
                    errors={errors.cep}
                    isLoading={getLoaderFromName('cep')}
                    touched
                    onKeyUp={(e) => handleCep(e, setValues, setFieldError)}
                  />
                ) : null}
              </div>

              {addressIsVisible ||
              initialValues.street ||
              (values.country.Pais?.id !== '76' && !!values.country) ? (
                <>
                  <div className="col-12 col-lg-6">
                    {console.log(values.state)}
                    <Select
                      label="Estado"
                      fieldName="state"
                      options={states}
                      onChange={setFieldValue}
                      getOptionLabel={(option) => option.Estado?.nome}
                      getOptionValue={(option) => option.Estado?.id}
                      onValueId={(item) =>
                        handleSelectState(setFieldValue, item.Estado?.id)
                      }
                      value={values.state}
                      errors={errors.state}
                      touched={touched.state}
                      isDisabled={getDisabledFieldFromName('state')}
                    />

                    <Select
                      label="Cidade"
                      fieldName="city"
                      options={cities}
                      onChange={setFieldValue}
                      getOptionLabel={(option) => option.Cidade?.nome}
                      getOptionValue={(option) => option.Cidade?.id}
                      value={values.city}
                      errors={errors.city}
                      touched={touched.city}
                      isDisabled={getDisabledFieldFromName('city')}
                    />

                    <Input
                      label="Rua"
                      fieldName="street"
                      errors={errors.street}
                      touched={touched.street}
                      isDisabled={getDisabledFieldFromName('street')}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Input
                      label="Bairro"
                      fieldName="neighborhood"
                      errors={errors.neighborhood}
                      touched={touched.neighborhood}
                      isDisabled={getDisabledFieldFromName('neighborhood')}
                    />

                    <Input
                      placeholder="Ex: 123"
                      label="Número"
                      fieldName="number"
                      fieldType="number"
                      errors={errors.number}
                      touched={touched.number}
                    />

                    <Input
                      placeholder="Ex: apt"
                      label="Complemento"
                      fieldName="complement"
                      errors={errors.complement}
                      touched={touched.complement}
                    />
                  </div>
                </>
              ) : null}
            </div>

            {isEdit ? (
              <div className="d-flex w-100 form-buttons">
                <Button
                  label="Cancelar"
                  color="secondary"
                  className="w-100 mr-2"
                  onClick={handleCancel}
                />
                <Button label="Salvar" className="w-100" onClick={handleEdit} />
              </div>
            ) : (
              <BottomNavigation
                isCompleted={isCompleted}
                onClickNext={handleNext}
                onClickPrev={handlePrev}
                className="justify-content-center"
                prevLabel="Voltar"
                nextLabel="Próximo"
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
